.ant-input:focus, 
.ant-picker:focus, 
.ant-select-selector:focus, 
.ant-select:focus, 
.ant-select-open:focus {
  border-color: var(--blue) !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(37, 54, 144, 0.2);
  box-shadow: 0 0 0 1px rgba(37, 54, 144, 0.2);
}

.ant-input, 
.ant-picker,
.ant-select, 
.ant-select-selector, 
.ant-select-open {
  /* border-color: var(--blue) !important; */
  outline: 0;
  -webkit-box-shadow: 0 0 0 1px rgba(37, 54, 144, 0.2);
  box-shadow: 0 0 0 1px rgba(37, 54, 144, 0.2);
  height: 5vh;
}

.form-item-label{
  /* font-family: Brandon Grotesque; */
  font-size: 14px;
  font-weight: 420;
}

.delivery-details-font {
  /* font-family: Hero New; */
  font-size: 25px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: 0em;
}

.submit-btn {
  width: 100%;
  background: #274193 !important;
  border: none;
  border-radius: 5px;
  color: white;
  height: 35px;
  font-weight: 600;
}


.submit-receipt-success-header {
  color: black;
  justify-content: center;
  align-items: center;
  /* font-family: Inter; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.receipt-success-button {
  width: 100%;
  /* background: #02BC7D !important; */
  border: none;
  border-radius: 10px;
  color: white;
  height: 40px;
  font-weight: 600;
}