#canvas {
    margin: 0 auto !important;
    top: 280px;
    width: 80%;
    /* left: 29px;
    position:absolute; */
}

.bottom-image-bg {
    width: 100%;
    position: absolute;
    z-index: 0;
    bottom: 5%;
}

.with-wind-spinner-mascot {
    /* width: 55%; */
    position:absolute;
    bottom: 7%;
    z-index: 100;
    left: 0;
}
.lion-dance-mascot  {
    width:40%;
    position:absolute;
    bottom: 20%;
    right: 0;
    z-index: 100;

}

@media (max-height: 700px) {
    .bottom-image-bg {
        width: 100%;
        position: absolute;
        z-index: 0;
        bottom: 6.5%;
    }
}