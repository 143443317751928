.submission-history-card {
    background-color: white;
    border-radius: 8px;
    height: 80px;
  }

.select-tag .ant-select-selector {
  width: 130px !important;
  border-radius: 16px !important;
  color: white !important;
  font-size: 12px !important;
  background-color:#274193 !important;
  font-weight: 400 !important;
  text-align: center;
}

.select-dropdown {
  background: #274193 !important;
  color: white !important
}

/* Style the select options */
.select-dropdown .ant-select-item {
  background: #274193 !important;
  color: white !important
}

.select-dropdown .ant-select-selection-item {
  color: white !important

}

.select-dropdown .ant-select-selection-placeholder {
  color: white !important
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  left: 5px;
}