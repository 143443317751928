.btn-color {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #C2C2C2;
  color:white;
  width: 50%;
  height:5vh;
}

.all-btn {
  border-radius: 10px;
  text-align: center;
  color: white;
  font-size: 16px;
  border: none;
  width:30%;
  height:3.5vh;
}

.my-reward-card {
  background-color: white;
  border-radius: 10px;
  /* padding-left: 10px; */
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.redeemItem-active-btn {
  width: 100%;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #30E700;
  border: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.redeemItem-inactive-btn {
  width: 100%;
  padding: 4px 8px;
  border-radius: 10px;
  background: #C2C2C2;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}



.rewards-point-container {
  display: flex;
  padding: 4px 10px;
  align-items: center;
  border-radius: 6px;
  background-color:  #D9D9D9;
  color: #000;
  font-family: Hero New;
  font-size: 16px;
  font-weight: 600;
}

.pointShop-container {
  display: grid;
  grid-template-columns: 150px 150px;
  gap: 10px;
  justify-content: space-evenly;
}

.select-option {
  background-color: black;
  color: white;
}

/* .ant-select-selector {
  background-color: black !important;
  align-items: center;
  border-radius: 16px !important;
} */

.ant-select-selection-search {
  color: white !important;
}

.ant-select-selection-item {
  color: white !important;
}

.ant-select-selection-placeholder {
  color: white !important;
}

.delivery-icon-container {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #9EEF89;
}

 .delivery-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* justify-content: space-between; */
}


 .ant-modal-close-x {
  /* background-color: #D2D2D2; */
  border-radius: 50px;

 }

 .custom-yellow-border-rate .ant-rate-star {
  border-color: yellow;
}


@media (min-width: 600px) {
  .delivery-container {
    display: flex;
    /* justify-content: center; */
    gap: 20px;
  }
 }
