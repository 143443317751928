.form-item-label {
  font-size: 0.8rem;
  font-weight: 700;
  /* color: black; */
}

.redirection-box {
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #274193;
  border-radius: 10px;
  color: #274193;
} 

.arrow-pos {
  /* transform: rotate(180deg); */
  width: 5%;
  height: 5%;
  align-items: center;
}

.logout-btn {
  background: #184072 !important;
  border-radius: 6px;
  height: 5vh;
  font-weight: 600;
  width: 100% !important;
  border: none;
  color:white;
  font-size: 16px;
}

.save-btn {
  background-color: #274193 !important;
  border-radius: 6px;
  height: 5vh;
  font-weight: 600;
  width: 100% !important;
  border: none;
  color:white;
  font-size: 16px;
}

.submit-receipt-success-header {
  color: black;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.update-details-success-header {
  color: black;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.receipt-success-button {
  width: 80%;
  border: none;
  border-radius: 10px;
  color: white;
  height: auto;
  font-weight: 600;
}

.profile-title-font {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color:#274193;
}
