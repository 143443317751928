.delivery-details-font {
    /* font-family: Hero New; */
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
}

.max-address-font {
    /* font-family: Hero New; */
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    position:relative;
    top: 12px;
    color: #454545;
}

.reward-card {
    background-color: white !important;
    border-radius: 10px;
    padding-left: 16px;
    height: 10vh;
    padding-top: 10px
  }

.edit-address-btn {
    width: 45%;
    background-color: black !important;
    height: 4vh;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
}

.delete-address-btn {
    width: 40px;
    background-color: black !important;
    height: 40px;
    border-radius: 50px;
    position: absolute;
    right: 0;
    top: 5px;
    color: white;
}

/* .receipt-success-button {
    width: 100%;
    border: none;
    border-radius: 10px;
    color: white;
    height: 40px;
    font-weight: 600;
  }
   */