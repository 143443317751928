@import url('./Fonts/Gotham/style.css');
@import url('./Fonts/Hero-New/style.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    /* min-height: 100vh; */
    margin: 0 auto !important;
    max-width: 500px;
}

.ml-1 {
    margin-left: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 40px;
}

.mt-5 {
    margin-top: 50px;
}

.t-white {
    color: white;
}

.t-black {
    color: black
}

.t-green {
    color: #30E700;
}

.t-pink {
    color: #FA00FF;
}

.t-blue {
    color: #274193;
}

.text-deco-none {
    text-decoration: none;
}

.text-deco-underline {
    text-decoration: underline;
}

.d-flex {
    display: flex;
}

.d-inline {
    display: inline;
}

.w-90 {
    width: 90%;
}

.w-10 {
    width: 10%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.text-center {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.mr-3 {
    margin-right: 30px;
}

.mr-4 {
    margin-right: 40px;
}

.fontSize-12 {
    font-size: 12px;
}

.fontSize-14 {
    font-size: 14px;
}

.fontSize-16 {
    font-size: 16px;
}

.fontSize-18 {
    font-size: 18px;
}

.fontSize-20 {
    font-size: 20px;
}

.fontSize-24 {
    font-size: 24px;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer;
}

.my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.justify-content-center {
    justify-content: center;
}

.user-container {
    background-color: #274193;
    height: 170px;
    border-radius: 25px 25px 25px 25px;
    color: white;
}

.ant-progress .ant-progress-inner {
    background: white;
    width: 100%;
}

.footer-container {
    width: 100%;
    background-color: #274193;
    display: flex;
    /* justify-content: space-evenly; */
    text-align: center;
    align-items: center;
}

.upload-receipt-foooter {
    font-size: 11.5px;
}

.img-upload-receipt {
    width: 80%;
}

.upload-receipt-foooter {
    position: relative;
    top: 3px;
}

@media (min-width: 500px) {

    .footer-container {
        width: 500px;
        /* background-color: #f37955; */
        background-color: #274193;
        display: flex;
        /* justify-content: space-evenly; */
        text-align: center;
        align-items: center;
    }

    .haleon-logo {
        width: 100%;
    }

}



/* @media (min-width : 1000px) {

    .haleon-logo {
        width: 110px;
    }

    .img-upload-receipt {
        width: 80%;
    }

} */


.ad-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-container-for-stores {
    position: relative;
    float: right;
    bottom: 230px;
    left: 10px;
}

/* Help Center page */
.ad-container-help-center {
    position: absolute;
    /* position: absolute; */
    margin-left: 260px;
    margin-top: -330px;
}


.ant-carousel {
    height: 40vh;
}

.coming-soon-button {
    width: 90%;
    background: #30E700 !important;
    border: none;
    border-radius: 5px;
    height: 35px;
}


.ant-drawer .ant-drawer-header .overflow-btn {
    border-bottom: none;
}

.ant-drawer .ant-drawer-body {
    padding: 0px 0px 0px 35px;
    overflow: auto;
}

.overflow-btn .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 0;
}

.minimized-panel {
    width: 90%;
    margin: 5px 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=text] {
    -moz-appearance: textfield;
}

/* .whatsapp-img {
    width: 18%;
    z-index: 1;
    position: absolute;
    left: 5px;
    margin-top: 10px;
} */


.auth-text-color.ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
    color: red !important;
    padding-top: 3%;
    background-color: transparent !important;
    padding-right: 80px;
}

/* 
.ant-input-affix-wrapper>input.ant-input {
    background-color: transparent;
    color: white !important;
} */

.search-bar.ant-input-affix-wrapper>input.ant-input {
    background-color: transparent;
    color: black !important;
}

a:hover {
    color: #274193 !important;
}

.qrScannerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.raya-spinning-wheel-color {
    background-color: linear-gradient(#00754A, #48CD50);
}