.copy-link-container {
    display: flex;
    width: 366px;
    height: 58px;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: black;
}


.copy-btn {
    width: 100%;
    padding: 8px;
    color: white;
    border-radius: 10px;
    background-color: #274193;
    border: none;
    font-weight: 700;
    height: 40px;
}
