@font-face {
    font-family: 'Hero New';
    font-style: normal;
    font-weight: 400; /* Regular font weight */
    src: local('Hero New Regular'), url('Hero New Regular.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: italic;
    font-weight: 400; /* Regular font weight */
    src: local('Hero New Regular Italic'), url('Hero New Regular Italic.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: normal;
    font-weight: 100; /* Thin font weight */
    src: local('Hero New Thin'), url('Hero New Thin.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: italic;
    font-weight: 100; /* Thin font weight */
    src: local('Hero New Thin Italic'), url('Hero New Thin Italic.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: normal;
    font-weight: 200; /* Extra Light font weight */
    src: local('Hero New UltraLight'), url('Hero New UltraLight.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: italic;
    font-weight: 200; /* Extra Light font weight */
    src: local('Hero New UltraLight Italic'), url('Hero New UltraLight Italic.woff') format('woff');
}

/* add bold and extra bold */
@font-face {
    font-family: 'Hero New';
    font-style: normal;
    font-weight: 700; /* Bold font weight */
    src: local('Hero New Bold'), url('Hero New Bold.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: italic;
    font-weight: 700; /* Bold font weight */
    src: local('Hero New Bold Italic'), url('Hero New Bold Italic.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: normal;
    font-weight: 800; /* Extra Bold font weight */
    src: local('Hero New ExtraBold'), url('Hero New ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Hero New';
    font-style: italic;
    font-weight: 800; /* Extra Bold font weight */
    src: local('Hero New ExtraBold Italic'), url('Hero New ExtraBold Italic.woff') format('woff');
}