.slick-slider{
  overflow: hidden !important;
}

.home-slicker .slick-list{
  margin-bottom: -1.5rem;
}


 /* ================================ Home.js ======================================= */
 .user-home-container {
  width: 100%;
  background-color: #274193;
  height: 170px;
  border-radius: 25px 25px 25px 25px;
  color:white;
  margin: auto;
  position: relative;
}

.ant-progress .ant-progress-inner {
  background: white;
  width: 100%;
}

.box-container-left {
  width: 48%;
  height: 12vh;
  color: white;
  box-shadow: -3px 3px 0px black;
  border-radius: 10px 10px 10px 10px;
  position:relative;
  background-color:#333F48;
}

.box-container-right {
  width: 48%;
  background-color: #7C878E;
  height: 12vh;
  color: white;
  box-shadow: 3px 4px 0px black;
  border-radius: 10px 10px 10px 10px;
  position:relative;
}


.rewards-position {
  width: 50%;
  justify-content: space-evenly;
  margin-bottom: 30px;
}


@media (min-width: 600px) {
  /* .box-container-left {
    width: 48%;
    height: 16vh;
    color: white;
    box-shadow: -3px 3px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
    background-color:#333F48;
  } */
  
  /* .box-container-right {
    width: 48%;
    background-color: #7C878E;
    height: 16vh;
    color: white;
    box-shadow: 3px 4px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
  } */
  
  .submission-card {
    width: 100%;
    background-color: white;
    height: 250px;
    border-radius: 10px 10px 10px 10px;
    color: black;
    border: 2px solid black;
    position: relative;
    z-index: 0;
  }  

}


.store-container {
  width: 100%;
  background-color: #333F48;
  height: 100px;
  border-radius: 10px 10px 10px 10px;
  color: white;
  margin: auto;
  box-shadow: -3px 4px 0px black;
  position: relative;
}


.carousel-container {
  width: 100%;
  background-color: white;
  height: 16vh;
  border-radius: 10px 10px 10px 10px;
  color: white;
  margin: auto;
}

.submission-card {
  width: 100%;
  background-color: white;
  height: 260px;
  border-radius: 10px 10px 10px 10px;
  color: black;
  border: 2px solid #333F48;
  position: relative;
  z-index: 0;
}

.header-card {
  width: 155px;
  background-color:white;
  border: 2px solid #333F48;
  position: absolute;
  margin-top: -18px;
  height: 30px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-left: -3px;
}

.mid-header-container {
  width: 100%;
  background-color:#333F48;
  border: 1px solid #333F48;
  height: 100px;
  /* height: 300px; */
  border-radius: 10px 10px 10px 10px;
  z-index: -1;
  /* justify-content: space-evenly; */
}


.star-brands-container .row {
  display: flex;
  justify-content: space-evenly;
}

.scroll-img {
  width: 100%;
  overflow: auto;
  overflow-x: scroll;
  white-space: nowrap;
}

.scroll-img::-webkit-scrollbar {
  display: none;
}

.star-brands-position {
  display: flex;
  justify-content: center;
  margin-top: 8%;
}


/* @media (max-width: 375px) {

  .submission-card {
    width: 100%;
    background-color: white;
    height: 245px;
    border-radius: 10px 10px 10px 10px;
    color: black;
    border: 2px solid black;
    position: relative;
    z-index: 0;
  }

  .user-home-container {
    width: 100%;
    background-color: black;
    height: 170px;
    border-radius: 25px 25px 25px 25px;
    color: white;
    margin: auto;
    box-shadow: -5px 5px 0px #30E700;
    position: relative;
  }
  
  .ant-progress .ant-progress-inner {
    background: white;
    width: 100%;
  }
  
  .box-container-left {
    width: 48%;
    height: 12vh;
    color: white;
    box-shadow: -3px 3px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
    background-color:#333F48;
  }
  
  .box-container-right {
    width: 48%;
    background-color: #7C878E;
    height: 12vh;
    color: white;
    box-shadow: 3px 4px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
  }
  
  .store-container {
    width: 100%;
    background-color: #333F48;
    height: 100px;
    border-radius: 10px 10px 10px 10px;
    color: white;
    margin: auto;
    box-shadow: -3px 4px 0px black;
    position: relative;
  }
  
  
  .carousel-container {
    width: 100%;
    background-color: white;
    height: 16vh;
    border-radius: 10px 10px 10px 10px;
    color: white;
    margin: auto;
  }
  
  .submission-card {
    width: 100%;
    background-color: white;
    height: 260px;
    border-radius: 10px 10px 10px 10px;
    color: black;
    border: 2px solid #333F48;
    position: relative;
    z-index: 0;
  }
  
  .header-card {
    width: 155px;
    background-color:white;
    border: 2px solid #333F48;
    position: absolute;
    margin-top: -18px;
    height: 30px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-left: -3px;
  }
  
  .mid-header-container {
    width: 100%;
    background-color:#333F48;
    border: 1px solid #333F48;
    height: 100px;
    border-radius: 10px 10px 10px 10px;
    z-index: -1;
  }
  
  .scroll-img {
    width: 100%;
    overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
  }
  
  .scroll-img::-webkit-scrollbar {
    display: none;
  }

}

@media (min-width: 450px) {
  .box-container-left {
    width: 48%;
    height: 14vh;
    color: white;
    box-shadow: -3px 3px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
    background-color:#333F48;
  }
  
  .box-container-right {
    width: 48%;
    background-color: #7C878E;
    height: 14vh;
    color: white;
    box-shadow: 3px 4px 0px black;
    border-radius: 10px 10px 10px 10px;
    position:relative;
  }
  
}

@media (min-width: 500px) {

  .star-brands-container .row {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .star-brands-position {
    display: flex;
    justify-content: center;
    margin-top: 20%;
  }

  .rewards-position {
    width: 50%;
    justify-content: space-evenly;
    margin-top: 5px !important;
  }
  
} */
